import React from 'react';
import { graphql } from 'gatsby';
import Container from '../components/container';
import GraphQLErrorList from '../components/graphql-error-list';
import SEO from '../components/blog/seo';
import Layout from '../containers/layout';
import 'bootstrap/dist/css/bootstrap.min.css';
import CompanyPage from '../components/layout/CompanyPage/companyPage2';
import { getLocaleWiseLabel as t } from '../lib/localeUtils';
import { Button } from 'react-bootstrap';
import { navigate } from 'gatsby';
import clientConfig from '../../client-config';
console.log('version 1');

export const query = graphql`
  query CompanyPageQuery {
    pageLabels_home: sanityPage(pageName: { eq: "Home" }) {
      labels {
        labelKey
        labelValue {
          heb
          en
          es
        }
      }
    }
    pageLabels_landing: sanityPage(pageName: { eq: "the_company" }) {
      labels {
        labelKey
        labelValue {
          heb
          en
          es
        }
      }
    }
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
  }
`;
const IndexPage = (props: any) => {
  console.log('in index');
  console.log(clientConfig.backend);
  const { data, errors } = props;
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }
  const labels = [...data.pageLabels_home.labels, ...data.pageLabels_landing.labels];

  return (
    <Layout>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <CompanyPage labelArr={labels} />
    </Layout>
  );
};

export default IndexPage;
