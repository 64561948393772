import React, { useRef } from 'react';
import styles from './companyPage2.module.css';
import Profile, { IProfile } from './ProfileComponent';

interface IProps {
  profiles: Array<IProfile>;
}

const ProfileSet = ({ profiles }: IProps) => {
  const teamGridRef_Row = useRef<HTMLDivElement>(null);
  let gridClass: string;
  switch (profiles.length) {
    case 1:
      gridClass = styles.Grid_1columns;
      break;

    case 2:
      gridClass = styles.Grid_2columns;
      break;

    case 3:
      gridClass = styles.Grid_3columns;
      break;

    default:
      throw new Error('unsupported length');
  }
  const classes = [styles.companyStaffGrid, gridClass].join(' ');
  return (
    <div className={classes} ref={teamGridRef_Row}>
      {profiles.reverse().map((person) => (
        <Profile key={person.emailAddress} parentGridRef={teamGridRef_Row} {...person} />
      ))}
    </div>
  );
};

export default ProfileSet;
