import React, { useRef } from 'react';
import styles from './companyPage2.module.css';
import { Form, Button, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import TEXTS from './hebrewTexts';
import SvgIcons from './SvgIcons';
import { sendGetInTouchMessage } from '../../../lib/getInTouchMessageHandler';
import ProfileSet from './ProfileSet_Component';

type AppProps = {
  labelArr: Array<object>;
};

const CompanyPage = ({ labelArr }: AppProps) => {
  const { register, handleSubmit } = useForm<{ name: string; mail: string; content: string }>();
  const [show, setShow] = React.useState(false);
  const [showOther, setShowOther] = React.useState(false);
  const onSubmit = async (data: { name: string; mail: string; content: string }) => {
    if (data.name != '' && data.mail != '' && data.content != '') {
      void sendGetInTouchMessage(data.name, data.mail, data.content)
        .then(() => {
          setShowOther(!showOther);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setShow(!show);
    }
  };
  const handleClose = () => {
    setShow(false);
  };

  const handleCloseOther = () => {
    setShowOther(false);
  };

  let getModals = () => (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body style={{ fontSize: '100%' }} className='text-right'>
          <br />
          <h6 className='text-right' id='alertText'>
            {TEXTS.pleaseFillAllFields}
          </h6>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            {TEXTS.close}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showOther} onHide={handleCloseOther}>
        <Modal.Body style={{ fontSize: '100%' }} className='text-right'>
          <br />
          <h6 className='text-right' id='alertText'>
            {TEXTS.thxForContactingUs}
          </h6>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseOther}>
            {TEXTS.close}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
  return (
    <>
      {getModals()}
      <div className={styles.aboutRoot}>
        <div className={styles.aboutCover}>
          <img src='/assets/images/about_page_cover.png' />
          <div className={styles.aboutCoverText}>
            <h3>{TEXTS.firstHeading}</h3>
            {TEXTS.firstText.map((item) => (
              <p dir='rtl'>{item}</p>
            ))}
          </div>
        </div>
        <div className={styles.coverBanner}>
          <p dir='rtl'>{TEXTS.bannerText}</p>
        </div>
        <div className={styles.initialGridWrap}>
          <div className={styles.gridItem}>
            <div className={styles.gridItemTextWrap}>
              <h3>{TEXTS.gridItemOneHeading}</h3>
              <p dir='rtl'>{TEXTS.gridItemOneText}</p>
            </div>
            <div className={styles.gridItemSvgWrap}>{SvgIcons.users}</div>
          </div>
          <div className={styles.gridItem}>
            <div className={styles.gridItemTextWrap}>
              <h3>{TEXTS.gridItemTwoHeading}</h3>
              <p dir='rtl'>{TEXTS.gridItemTwoText}</p>
            </div>
            <div className={styles.gridItemSvgWrap}>{SvgIcons.chat}</div>
          </div>
          <div className={styles.gridItem}>
            <div className={styles.gridItemTextWrap}>
              <h3>{TEXTS.gridItemThreeHeading}</h3>
              <p dir='rtl'>{TEXTS.gridItemThreeText}</p>
            </div>
            <div className={styles.gridItemSvgWrap}>{SvgIcons.database}</div>
          </div>
          <div className={styles.gridItem}>
            <div className={styles.gridItemTextWrap}>
              <h3>{TEXTS.gridItemFourHeading}</h3>
              <p dir='rtl'>{TEXTS.gridItemFourText}</p>
            </div>
            <div className={styles.gridItemSvgWrap}>{SvgIcons.range}</div>
          </div>
          <div className={styles.gridItem}>
            <div className={styles.gridItemTextWrap}>
              <h3>{TEXTS.gridItemFiveHeading}</h3>
              <p dir='rtl'>{TEXTS.gridItemFiveText}</p>
            </div>
            <div className={styles.gridItemSvgWrap}>{SvgIcons.percent}</div>
          </div>
          <div className={styles.gridItem}>
            <div className={styles.gridItemTextWrap}>
              <h3>{TEXTS.gridItemSixHeading}</h3>
              <p dir='rtl'>{TEXTS.gridItemSixText}</p>
            </div>
            <div className={styles.gridItemSvgWrap}>{SvgIcons.shield}</div>
          </div>
          <div></div>
          <div className={styles.gridItem}>
            <div className={styles.gridItemTextWrap}>
              <h3>{TEXTS.gridItemSevenHeading}</h3>
              <p dir='rtl'>{TEXTS.gridItemSevenText}</p>
            </div>
            <div className={styles.gridItemSvgWrap}>{SvgIcons.briefcase}</div>
          </div>
        </div>
        <div className={styles.staffCover} id='team'>
          <h3>{TEXTS.staffCoverHeading}</h3>
          <p dir='rtl'>{TEXTS.staffCoverText}</p>
        </div>
        <div className={styles.companyStaffHeading}>{TEXTS.companyStaffHeading}</div>
        <ProfileSet profiles={TEXTS.profilesRow1} />
        <ProfileSet profiles={TEXTS.profilesRow2} />
        <ProfileSet profiles={TEXTS.profilesRow3} />
        <ProfileSet profiles={TEXTS.profilesRow4} />
        <div className={styles.companyStaffHeadingTwo}>{TEXTS.companyBoardHeading}</div>
        <div className={styles.companyBoardGrid}>
          <div>
            <div className={styles.companyBoardGridData}>
              <h3>{TEXTS.companyBoardMemberOne}</h3>
              <h5>{TEXTS.companyBoardMemberOneTitle}</h5>
              <div className={styles.companyBoardGridLinks}>
                <a href={`https://www.linkedin.com/in/amitkahana/`} target='_blank' rel='noopener noreferrer'>
                  {SvgIcons.linkedin}
                </a>
              </div>
            </div>
            <img
              src='/assets/images/team/AmitKahana.png'
              style={{ width: '225px', margin: '25px 0px', height: '185px' }}
            />
          </div>
          <div>
            <div className={styles.companyBoardGridData}>
              <h3>{TEXTS.companyBoardMemberTwo}</h3>
              <h5>{TEXTS.companyBoardMemberTwoTitle}</h5>
              <div className={styles.companyBoardGridLinks}>
                <a
                  href={`https://www.linkedin.com/in/uriel-bendavid-b8a62119/`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {SvgIcons.linkedin}
                </a>
              </div>
            </div>
            <img src='/assets/images/team/UrielBenDavid.png' />
          </div>
        </div>
        <div className={styles.staffCover} id='partners'>
          <h3>{TEXTS.businessPartnerHeading}</h3>
          <p dir='rtl'>{TEXTS.businessPartnerText}</p>
        </div>
        <div className={styles.initialGridWrap}>
          <div className={styles.businessGridItem}>
            <img src='/assets/images/the_company_partners_body_scandinavian_logo.png' />
            <h3>{TEXTS.businessPartnerOneHeading}</h3>
            <p dir='rtl'>{TEXTS.businessPartnerOneText}</p>
          </div>
          {/* <div className={styles.businessGridItem}>
            <img src='/assets/images/the_company_partners_body_payme_logo.png' />
            <h3>{TEXTS.businessPartnerTwoHeading}</h3>
            <p dir='rtl'>{TEXTS.businessPartnerTwoText}</p>
          </div> */}
        </div>
        <div className={styles.staffCover} id='comp'>
          <h3>{TEXTS.supervisionHeading}</h3>
          <p dir='rtl'>{TEXTS.supervisionText}</p>
        </div>
        <div className={styles.initialGridWrap}>
          {/* <div className={styles.supervisionGridItem}>
            {/* <img
              src='/assets/images/the_company_comp_body_boi_logo.png'
              className={styles.supervisionGridItemSmallImage}
            /> * /}
            <h3>{TEXTS.supervisionOneHeading}</h3>
            <p dir='rtl'>{TEXTS.supervisionOneText}</p>
          </div>
          <div className={styles.supervisionGridItem}>
            {/* <img
              src='/assets/images/the_company_comp_body_mof_logo.png'
              className={styles.supervisionGridItemSmallImage}
            /> * /}
            <h3>{TEXTS.supervisionTwoHeading}</h3>
            <p dir='rtl'>{TEXTS.supervisionTwoText}</p>
          </div>
          <div className={styles.supervisionGridItem}>
            {/* <img
              src='/assets/images/the_company_comp_body_tax_logo.png'
              className={styles.supervisionGridItemSmallImage}
            /> * /}
            <h3>{TEXTS.supervisionThreeHeading}</h3>
            <p dir='rtl'>{TEXTS.supervisionThreeText}</p>
          </div>
          <div className={styles.supervisionGridItem}>
            {/* <img src='/assets/images/the_company_comp_body_AML_logo.png' style={{ width: '150px' }} /> * /}
            <h3>{TEXTS.supervisionFourHeading}</h3>
            <p dir='rtl'>{TEXTS.supervisionFourText}</p>
          </div> */}
          <div className={styles.supervisionGridItem}>
            <img src='/assets/images/the_company_comp_body_rcg_logo.jpeg' style={{ width: '150px' }} />
            <h3>{TEXTS.supervisionFiveHeading}</h3>
            <p dir='rtl'>{TEXTS.supervisionFiveText}</p>
          </div>
        </div>
        <div className={styles.contactUsWrap}>
          <div className={styles.contactUsForm}>
            <h3>דברו איתנו</h3>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group controlId='formBasicName'>
                <Form.Control
                  className={styles.contactName}
                  type='text'
                  name='name'
                  dir='rtl'
                  ref={register}
                  placeholder='שם מלא'
                />
              </Form.Group>
              <Form.Group controlId='formBasicEmail'>
                <Form.Control
                  className={styles.contactEmail}
                  type='email'
                  name='mail'
                  dir='rtl'
                  ref={register}
                  placeholder='אימייל'
                />
              </Form.Group>
              <Form.Group controlId='exampleForm.ControlTextarea1'>
                <Form.Control
                  className={styles.contactReferal}
                  as='textarea'
                  name='content'
                  placeholder='תוכן הפנייה'
                  dir='rtl'
                  ref={register}
                  rows={5}
                />
              </Form.Group>
              <Button type='submit' className={styles.contactUsButton}>
                שליחה
              </Button>
            </Form>
          </div>
          <div className={styles.contactUsImgWrap}>
            <img src='/assets/images/_about_page_contact_us_image.png' />
            <h3 className={styles.contactUsText}>אנחנו עוסקים במתן מימון יעיל לעסקים</h3>
            <h4 className={styles.contactUsQuote}>״</h4>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyPage;
