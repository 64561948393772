import React, { RefObject, useState } from 'react';
import styles from './ReadMoreContent.module.css';

type IContentProps = {
  content: string;
  noOfCharactersToShowByDefault?: number;
  focusToElementRef?: RefObject<HTMLDivElement>;
};

const ReadMoreContent = (props: IContentProps) => {
  const content = props.content;
  const charactersToShow = props.noOfCharactersToShowByDefault || 100;
  const isContentLong = charactersToShow < content.length;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    if (isReadMore) {
      setIsReadMore(false);
    } else {
      setIsReadMore(true);
      const ref = props.focusToElementRef;
      if (ref && ref.current) {
        ref.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }
  };

  let parsedContent: string;
  if (isContentLong) {
    parsedContent = isReadMore ? `${content.slice(0, charactersToShow)}...`.replace('....', '...') : content;
    parsedContent += ' ';
  } else {
    parsedContent = content;
  }

  return (
    <>
      <p dir='rtl'>
        {parsedContent}
        {isContentLong && (
          <a onClick={toggleReadMore} className={styles.readMoreBtn}>
            {isReadMore ? 'קראו עוד' : 'קראו פחות'}
          </a>
        )}
      </p>
    </>
  );
};

export default ReadMoreContent;
