import { IProfile } from './ProfileComponent';

const profilesRow1: Array<IProfile> = [
  {
    name: 'יונתן ברנד',
    role: 'מנכ"ל',
    description:
      'מקים קרן ההשקעות פינטק הראשון בישראל. פיתח וניהל את פלטפורמת מימון העסקים האלטרנטיבית הגדולה בישראל. עו״ד מוסמך, בוגר תואר ראשון ושני מטעם האוניברסיטה העברית. פתח את העסק הראשון בגיל 15 ומודה להוריו שהסיעו אותו בין לקוחות. אוהב אוכל וסנואובורד. לא חשוב הסדר.',
    profileImgPath: '/assets/images/team/YonatanBrand.jpg',
    emailAddress: 'yonatanb@habankaim.com',
    linkedInAddress: 'yonatan-brand-19312454',
  },
  {
    name: 'ציון רווח',
    role: 'סמנכ"ל אשראי',
    description: `בוגר כלכלה וניהול אונ' חיפה ומוסמך במנהל עסקים MBA מטעם אונ' תל אביב. 22 שנות עבודה בבנק איגוד, בתפקידי ניהול אשראי בכירים. תפקיד אחרון כמנהל מרכז העסקים הראשי של הבנק. חולה על "בתים במיליון דולר",  אפל על פני אנדרואיד ואוהב לשמוע מוזיקה טובה.`,
    profileImgPath: '/assets/images/team/ZionRevach.jpeg',
    emailAddress: 'zion@habankaim.com',
    linkedInAddress: 'zion-revach-749a42152',
  },
  {
    name: 'אבי אסולין',
    role: 'סמנכ"ל כספים',
    description: `סמנכ״ל כספים מנוסה בחברות ציבוריות. לשעבר סמנכ״ל כספים בא.ס. אקורד ושותף ביקורת שטיינמיץ עמינוח.`,
    profileImgPath: '/assets/images/team/AviAssulin.jpeg',
    emailAddress: 'avi@habankaim.com',
    linkedInAddress: 'avi-assulin-08a675151',
  },
];
const profilesRow2: Array<IProfile> = [
  {
    name: 'רן סיני',
    role: 'כלכלן ראשי',
    description: `מומחה לתחום המימון והאשראי, יועץ פיננסי לכמה מהגופים הגדולים והמוכרים במשק. בעברו היה מנהל הכספים של אגף לקוחות עסקיים בחברת כרטיסי אשראי. ניהל יחידת אנליזה כלכלית בחברה גלובאלית מהגדולות בעולם בתחומה. בעל תואר ראשון בכלכלה ומנהל עסקים מאונ' בר אילן ו- MBA מאוניברסיטת תל אביב עם התמחות במימון וחשבונאות. מכור לכל מה שקשור ללחימה משולבת ואב ל- 2 בנות שלא רואות אותו ממטר.`,
    profileImgPath: '/assets/images/team/RanSinai.jpeg',
    emailAddress: 'rans@habankaim.com',
    linkedInAddress: 'ran-sinai-7a2bb967',
  },
  {
    name: 'אסף שיפר',
    role: 'סמנכ"ל טכנולוגיות',
    description: `20 שנות ניסיון בהייטק, בתפקידים מרכזיים של פיתוח, מוצר וניהול, בארגונים המובילים בארץ ובעולם. בעלים של בית תוכנה בינלאומי קטן עם סניפים בארץ ובהודו. חבר בלשכת עוה"ד, סרן במיל' בוגר ממר"ם, נשוי ואבא לשלושה (סדר חשיבות עולה).`,
    profileImgPath: '/assets/images/team/AsafShifer.png',
    emailAddress: 'asaf@habankaim.com',
    linkedInAddress: 'asafshifer',
  },
];
const profilesRow3: Array<IProfile> = [
  {
    name: 'רועי לנגי',
    role: 'בנקאי מימון',
    description: `יוצא יחידה מיוחדת ומובחרת. בעל ניסיון בשיווק ומכירות בישראל ובארה"ב. סטודנט שנה שלישית בכלכלה, מתעסק באופן יומי בבנקאות מימון וחיתום אשראי . בגיל 23 בלבד רכש ושיפץ לבדו את דירתו הראשונה בהוד השרון. בעל רישיון סקיפר.`,
    profileImgPath: '/assets/images/team/RoyLengy.png',
    emailAddress: 'roy@habankaim.com',
    linkedInAddress: 'roey-lengy',
  },
  {
    name: 'רוני מרקוביץ',
    role: 'בנקאית מימון',
    description:
      'סטודנטית שנה שלישית למנהל עסקים ולימודי אסיה באוניברסיטה העברית בירושלים. בעלת נסיון בפיתוח עסקי בינלאומי, יצירת קשרים ושיתופי פעולה עסקיים, והערכת מימון לפרויקטים. אוהבת לטייל ולצאת לטבע, מנסה לשלב גם קצת סקי או צלילה כשאפשר.',
    profileImgPath: '/assets/images/team/RoniMarkovitz.jpeg',
    emailAddress: 'roni@habankaim.com',
    linkedInAddress: 'roni-markovitz',
  },
  {
    name: 'טל שמואלי',
    role: 'בנקאי מימון',
    description:
      'בן 25, מתעמל מכשירים לשעבר וספורטאי בנשמה. יוצא יחידה מובחרת מצה"ל, ובתפקידו האחרון עבד כאנליסט בקרן הון-סיכון. כיום סטודנט לכלכלה ומדעי המחשב באוניברסיטה הפתוחה.',
    profileImgPath: '/assets/images/team/TalShmuely.jpeg',
    emailAddress: 'tal@habankaim.com',
    linkedInAddress: 'tal-shmuely-014400197',
  },
];
const profilesRow4: Array<IProfile> = [
  {
    name: 'עמית יוסף',
    role: 'עורך דין',
    description:
      'עו"ד ומגשר מוסמך המתמחה בסדר הדין האזרחי מסחרי בעיקר בתחום הבנקאות. בעל ניסיון רב בניהול תיקי ליטיגציה. גולש גלים וחובב ספורט וטיולים.',
    profileImgPath: '/assets/images/team/AmitYosef.jpeg',
    emailAddress: 'amity@habankaim.com',
    linkedInAddress: 'amit-yosef-9555bb125',
  },
];

export default {
  profilesRow1,
  profilesRow2,
  profilesRow3,
  profilesRow4,
  close: 'סגור',
  pleaseFillAllFields: 'אנא מלא את כל השדות',
  thxForContactingUs: 'תודה על הפנייה, נחזור אלייך בהקדם בשעות העבודה',
  firstHeading: 'החברה',
  firstText: [
    'אולטרה היא חברת אשראי.',
    'חברת אשראי זה כמו בנק? דומה, אבל לא.',
    'אנחנו לא בנק. אנחנו שקופים, יעילים וגמישים.',
    'אנחנו גם לא מונופול מפלצתי שעושה המון',
    'דברים בצורה בינונית. אנחנו עוסקים במימון',
    'עסקים בלבד, ואנחנו עושים את זה מעולה.',
  ],
  bannerText:
    'אולטרה מאוגדת בישראל כחברה פרטית ישראלית בערבון מוגבל תחת השם אף.טי.פי.אף בע״מ ח.פ. 515953990. החברה הוקמה בשנת 2019 על ידי צוות מגוון ומנוסה של יוצאי מערכות פיננסיות שונות. החברה משלבת טכנולוגיות מתקדמות לצד ידע מסורתי של בנקאות מימון שנועד להביא לשוק מוצר חדש לתחום מימון העסקים הקטנים. החברה אינה בנק ולא מתיימרת להיות כזו. בדיוק להפך.',
  gridItemOneHeading: 'שותפים',
  gridItemOneText:
    'יוצרים שותפיות למתן אשראי שמקדמות סינרגיה בין קבוצות עסקיות ומאפשרות תנאי אשראי טובים יותר לחברים בהן.',
  gridItemTwoHeading: 'מלווים',
  gridItemTwoText:
    'מספקים את השירות הבנקאי מימוני והכסף שיאפשר לעסק שלך לעבוד. לומדים את הצרכים והאופי של העסק, מתאימים את מוצרי האשראי הנכונים ומספרים אותם. עושים את זה בצורה מקצועית ושירותית, במחירים הוגנים ובנוחות.',
  gridItemThreeHeading: 'מבוססי נתונים',
  gridItemThreeText:
    'באישור הלקוחות שלנו אנחנו אוספים את כל המידע שאנחנו יכולים עליהם. באופן אוטומטי ממגוון מערכות כדי להתאים להם את מוצר האשראי שהכי מתאים להם.',
  gridItemFourHeading: 'מתקדמים',
  gridItemFourText: `פיתחנו אלגוריתם חיתום היברידי מתקדם ואדפטיבי אשר משלב טכנולוגיה, תובנות פיננסיות וגורמים אנושיים. אנחנו בעיקר מבינים שבשנת ${new Date().getFullYear()} אין סיבה לטרטר אותך לסניף או לשלוח פקס. יש דרך אחרת לעבוד עם בעלי עסקים.`,
  gridItemFiveHeading: 'מתמטיים',
  gridItemFiveText:
    'מתמחרים את הריבית והעמלות שלנו באופן מקצועי ואנליטי מבוסס על אלגוריתם מתוחכם. לכל עסק ולכל הלוואה יש ציון שמחושב על בסיס כל הנתונים שיש ברשותנו וממנו נגזרת הריבית והעמלות. כי לא כל העסקים זהים ואין סיבה שאחד טוב יממן סיכון של עסק רע רק בגלל שבבנק מעדיפים לעבוד עם תעריפון קבוע.',
  gridItemSixHeading: 'מפוקחים',
  gridItemSixText:
    'חברה ישראלית פרטית בעלת רישיון מתן אשראי מורחב לפי חוק הפיקוח על שירותים פיננסים מוסדרים. החברה הייתה מהראשונות שקיבלו רישיון קבוע על פי החוק החדש.',
  gridItemSevenHeading: 'מקצוענים',
  gridItemSevenText:
    'בנקאי מימון לעסקים מהשורה הראשונה, חיים עסקים קטנים ומתמחים בנישות עסקיות ספציפיות. לא פקידים אפורים שיושבים מאחורי שולחן.',
  staffCoverHeading: 'צוות אולטרה',
  staffCoverText:
    'אולטרה היא חברה של מקצוענים לבנקאות מימון שהחליטו לעשות את מה שהם הכי טובים בו, בלי הבנק. באולטרה הבינו שהדבר שעומד בין מה שמימון עסקים יכול להיות (כמו שהם רוצים לעשות אותו) למציאות זה… הבנק. אז הנה הצוות, בנקאי מימון בלי בנק.',
  companyStaffHeading: 'צוות החברה',
  companyStaffOneHeading: 'רן סיני',
  companyStaffOneTitle: 'סמנכ״ל כספים',
  companyStaffOneText: `מומחה לתחום המימון והאשראי, יועץ פיננסי לכמה מהגופים הגדולים והמוכרים במשק. בעברו היה מנהל הכספים של אגף לקוחות עסקיים בחברת כרטיסי אשראי. ניהל יחידת אנליזה כלכלית בחברה גלובאלית מהגדולות בעולם בתחומה. בעל תואר ראשון בכלכלה ומנהל עסקים מאונ' בר אילן ו- MBA מאוניברסיטת תל אביב עם התמחות במימון וחשבונאות. מכור לכל מה שקשור ללחימה משולבת ואב ל- 2 בנות שלא רואות אותו ממטר.`,
  companyStaffTwoHeading: 'ציון רווח',
  companyStaffTwoTitle: 'סמנכ"ל אשראי',
  companyStaffTwoText: `בוגר כלכלה וניהול אונ' חיפה ומוסמך במנהל עסקים MBA מטעם אונ' תל אביב. 22 שנות עבודה בבנק איגוד, בתפקידי ניהול אשראי בכירים. תפקיד אחרון כמנהל מרכז העסקים הראשי של הבנק. חולה על "בתים במיליון דולר",  אפל על פני אנדרואיד ואוהב לשמוע מוזיקה טובה`,
  companyStaffThreeHeading: 'יונתן ברנד',
  companyStaffThreeTitle: 'מנכ"ל',
  companyStaffThreeText:
    'מקים קרן ההשקעות פינטק הראשון בישראל. פיתח וניהל את פלטפורמת מימון העסקים האלטרנטיבית הגדולה בישראל. עו״ד מוסמך, בוגר תואר ראשון ושני מטעם האוניברסיטה העברית. פתח את העסק הראשון בגיל 15 ומודה להוריו שהסיעו אותו בין לקוחות. אוהב אוכל וסנואובורד. לא חשוב הסדר.',
  companyBoardHeading: 'דירקטוריון ויועצים',
  companyBoardMemberOne: 'עמית כהנא',
  companyBoardMemberOneTitle: 'יועץ פינטק',
  companyBoardMemberTwo: 'אוריאל בן דוד',
  companyBoardMemberTwoTitle: 'יו״ר הדירקטוריון',
  businessPartnerHeading: 'שותפים עסקיים',
  businessPartnerText:
    'באולטרה יוצרים שותפיות עסקיות למתן אשראי טוב יותר. אנחנו מתאמצים ליצור מנגנונים שיאפשרו ללקוחות שלנו לקבל הלוואות טובות יותר באמצעות מנגנונים של מתן ערבויות, העברת מידע ועוד.',
  businessPartnerOneHeading: 'סקנדינאביאן מרין',
  businessPartnerOneText: 'ענף מסחר מזון בינלאומי. שיתוף פעולה למימון לקוחות באמצעות מידע עסקי ויצירת בטוחות.',
  businessPartnerTwoHeading: 'פיי מי',
  businessPartnerTwoText: 'שחקן מוביל בתחום סליקת האשראי. התממשקות לקבלת מידע עסקי ומימון חשבוניות לעסקים קטנים.',
  supervisionHeading: 'ציות ופיקוח',
  supervisionText:
    'יש דבר אחד שאולטרה עושים כמו בנק. שומרים על סטנדרט הציות והפיקוח המירבי. על מנת ששוק המימון ימשיך ויתפתח באולטרה תומכים ברגולטורים השונים המפקחים על שוק האשראי בישראל לשמירה על יציבותו ופיתוחו.',
  supervisionOneHeading: 'בנק ישראל',
  supervisionOneText:
    'בנק ישראל הינו הבנק המרכזי של מדינת ישראל. כגורם עצמאי הוא חותר לשמור על יציבות מחירים, השגת יעדי הממשלה בתחום כלכליים שונים ושמירה על יציבות המערכת הפיננסית. למרות שהוראות בנק ישראל אינם מחייבות את אולטרה, החברה מכבדת את מקצעיות הבנק ובחורת מרצון לעמוד במגוון הוראות ניהול בנקאי תקין.',
  supervisionTwoHeading: 'רשות שוק ההון הביטוח והחיסכון',
  supervisionTwoText:
    'הרשות שמהווה את הרגולטור של אולטרה ושל כל שאר חברות מתן האשראי מכוח חוק הפיקוח על שירותים פיננסים מוסדרים. אולטרה גאים להיות מחברות המימון הראשונות שקיבלו רישיון בהתאם לחקיקה החדשה והמחמירה ומהחברות הבודדות שמחזיקות ברישיון אשראי מורחב.',
  supervisionThreeHeading: 'רשות המיסים',
  supervisionThreeText: 'רגולטור נוסף המקבל מידע על פי החוק מחברות האשראי במטרה לפקח על תשלומי המיסים בישראל.',
  supervisionFourHeading: 'הרשות לאיסור הלבנת הון ומימון טרור',
  supervisionFourText:
    'הרשות שאחראית למניעת פשיעה כלכלית מתחומי הלבנת ההון ומימון הטרור. באולטרה רואים חשיבות יתרה בהקפדה יתרה על כל הוראות החוק. באולטרה מממנים עסקים מוצלחים בעלי סיכון אשראי הגיוני ולכן בראש ובראשונה החיתום של אולטרה מחמיר יותר מדרישות הרשות הלבנת ההון.',
  supervisionFiveHeading: 'פירמת הייעוץ רגולציה',
  supervisionFiveText:
    'באולטרה עובדים עם חברת הייעוץ המובילה בישראל לרגולציה פיננסית. נהלי העבודה, הדרכת העובדים, מערכות החברה וכו׳ מותאמים באופן שוטף לסטנדרט המתקדם ביותר בישראל.',
};
