import React, { CSSProperties, RefObject } from 'react';
import styles from './ProfileComponent.module.css';
import SvgIcons from './SvgIcons';
import ReadMoreContent from '../../common/ReadMoreContent';

export interface IProfile {
  name: string;
  role: string;
  description: string;
  profileImgPath: string;
  emailAddress: string;
  linkedInAddress?: string;
}

interface IProps extends IProfile {
  parentGridRef: RefObject<HTMLDivElement>;
}

const Profile = ({ parentGridRef, profileImgPath, name, role, description, emailAddress, linkedInAddress }: IProps) => {
  const emailIcon = emailAddress ? (
    <a href={`mailto:${emailAddress}`} target='_blank' rel='noopener noreferrer'>
      {SvgIcons.mail}
    </a>
  ) : null;
  let linkedInIcon: JSX.Element | null = null;
  if (linkedInAddress) {
    const parsedLinkedInAddress = linkedInAddress.toLowerCase().startsWith('http')
      ? linkedInAddress
      : `https://www.linkedin.com/in/${linkedInAddress}`;
    linkedInIcon = (
      <a href={parsedLinkedInAddress} target='_blank' rel='noopener noreferrer'>
        {SvgIcons.linkedin}
      </a>
    );
  }

  const extraMargin: CSSProperties = {
    margin: '35px',
  };
  return (
    <div style={{ maxWidth: '450px', marginBottom: '50px' }}>
      <img
        src={profileImgPath}
        style={{
          maxWidth: '210px',
          maxHeight: '210px',
          borderRadius: '100%',
        }}
      />
      <h3>{name}</h3>
      <h6>{role}</h6>
      <ReadMoreContent content={description} focusToElementRef={parentGridRef} />
      <div className={styles.companyStaffGridLinks}>
        {emailIcon}
        {linkedInIcon}
      </div>
    </div>
  );
};

export default Profile;
